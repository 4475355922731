import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/worker/code/src/templates/blog-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`"Who knew we had a team of professional bowlers?" Arlyn asked Jake as she looked at the scoreboard following PayPerks' first bowling competition at Bowlmor Lanes.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1000px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "68%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAOABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAIBAwT/xAAWAQEBAQAAAAAAAAAAAAAAAAADAQL/2gAMAwEAAhADEAAAAcEKpbpHEn//xAAZEAACAwEAAAAAAAAAAAAAAAAAAQIRIRD/2gAIAQEAAQUCSI7zU3ZR/8QAGBEAAgMAAAAAAAAAAAAAAAAAAAECETH/2gAIAQMBAT8Bc3hR/8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQIBAT8BV//EABQQAQAAAAAAAAAAAAAAAAAAACD/2gAIAQEABj8CX//EABkQAQADAQEAAAAAAAAAAAAAAAEAESExcf/aAAgBAQABPyG5aDNdlGth4RW9Yqi4k6xu9n//2gAMAwEAAgADAAAAEFvv/8QAFhEBAQEAAAAAAAAAAAAAAAAAARAR/9oACAEDAQE/EGGGP//EABcRAQEBAQAAAAAAAAAAAAAAAAEAESH/2gAIAQIBAT8QC29v/8QAGxAAAwEAAwEAAAAAAAAAAAAAAREhADFBUXH/2gAIAQEAAT8Qd4NMR/M+HmnECiBWARHCB3hAFK91SN//2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["https://cdn.payperks.com/payperks-corporate/1.16.1/static/8c9a191b1bf7454935e9abfbf598f9bd/7f61c/Photo-Oct-15-10-32-29-PM-2_smaller.webp 400w", "https://cdn.payperks.com/payperks-corporate/1.16.1/static/8c9a191b1bf7454935e9abfbf598f9bd/d00b9/Photo-Oct-15-10-32-29-PM-2_smaller.webp 800w", "https://cdn.payperks.com/payperks-corporate/1.16.1/static/8c9a191b1bf7454935e9abfbf598f9bd/a5d4d/Photo-Oct-15-10-32-29-PM-2_smaller.webp 1000w"],
            "sizes": "(max-width: 1000px) 100vw, 1000px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["https://cdn.payperks.com/payperks-corporate/1.16.1/static/8c9a191b1bf7454935e9abfbf598f9bd/066f9/Photo-Oct-15-10-32-29-PM-2_smaller.jpg 400w", "https://cdn.payperks.com/payperks-corporate/1.16.1/static/8c9a191b1bf7454935e9abfbf598f9bd/4b190/Photo-Oct-15-10-32-29-PM-2_smaller.jpg 800w", "https://cdn.payperks.com/payperks-corporate/1.16.1/static/8c9a191b1bf7454935e9abfbf598f9bd/a2510/Photo-Oct-15-10-32-29-PM-2_smaller.jpg 1000w"],
            "sizes": "(max-width: 1000px) 100vw, 1000px",
            "type": "image/jpeg"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "https://cdn.payperks.com/payperks-corporate/1.16.1/static/8c9a191b1bf7454935e9abfbf598f9bd/a2510/Photo-Oct-15-10-32-29-PM-2_smaller.jpg",
            "alt": "Photo Oct 15 10 32 29 PM 2 smaller",
            "title": "Photo Oct 15 10 32 29 PM 2 smaller",
            "loading": "lazy",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>
    <p>{`If you judge a "winner" by who struck down the most pins, then Tim was definitely it.`}</p>
    <p>{`But if you count creativity and dancability and spirit into the criteria (which, I’m just saying, count for a lot), then I was the clear favorite.`}</p>
    <p>{`Caroline wins for choosing `}<a parentName="p" {...{
        "href": "http://www.ippudony.com/"
      }}>{`Ippudo`}</a>{` for pre-bowling bowls of soup and for cleverly naming the event "Bowls and Bowls".`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      